import type { IUser, IUserGenerationLimits } from "~/solidJs/shared/helpers/types/IUser";
import { createContext, createEffect, createSignal } from "solid-js";
import * as amplitude from "@amplitude/analytics-browser";
import { addPrefixToKeys } from "~/js/utilities/addPrefixToKeys";
import {
	GenerationCounter,
	foundDiffBetweenTwoPrimitiveObjects,
	type primitives
} from "~/solidJs/shared/helpers";
import { signOut } from "supertokens-web-js/lib/build/recipe/thirdpartyemailpassword";
import { config } from "~/js/network/network-handler";
import type { FilterObjectByValue } from "~/solidJs/shared/helpers/types/ObjectHelpersTypes";
type userStoreLimitType = Partial<IUserGenerationLimits>;
export type IUserStore = IUser & userStoreLimitType;
export const [getUserStore, setUserStore] = createSignal<IUserStore | undefined | null>();
const identifyEvent = new amplitude.Identify();
const disabledPropertiesLookUp = {
	status: true,
	start_time: true,
	end_time: true,
	"st-ev": true,
	subscription: true,
	group: true,
	next_payment: true
} as const satisfies Partial<Record<keyof IUserStore, boolean>>;
export const generationCounter = new GenerationCounter(["qustionsCounter"] as const, 0);

const updateGenerationCounter = (user: IUserStore) => {
	if (user.total_runs === undefined || user.available_runs === undefined) return;
	const runs = user.total_runs - user.available_runs;
	if (!generationCounter.inited) {
		generationCounter.setCurCounter(runs);
		return;
	}
	generationCounter.updatePrefixes(runs);
};
export const getNonDisabledUserFields = (user: IUserStore) => {
	const record = {} as FilterObjectByValue<IUserStore, primitives>;
	for (const key in user) {
		if (key in disabledPropertiesLookUp) {
			continue;
		}
		// @ts-ignore
		record[key] = user[key];
	}
	return record;
};
createEffect((prev: FilterObjectByValue<IUserStore, primitives> | undefined) => {
	const user = getUserStore();
	if (!user) return user;
	const primitveUser = getNonDisabledUserFields(user);
	if (primitveUser.banned_at) {
		signOut();
		document.querySelector("#banned-banner")?.classList?.remove("hidden");
		return;
	}
	let diff = null;
	updateGenerationCounter(primitveUser);
	const prefixedUser = addPrefixToKeys(primitveUser, "user_");
	if (prev) {
		diff = foundDiffBetweenTwoPrimitiveObjects(addPrefixToKeys(prev, "user_"), prefixedUser);
		if (diff.length < 1) return primitveUser;

		for (const [key, value] of diff) {
			identifyEvent.set(key, value || false);
		}
		if (config.FRONT_CANARY) {
			identifyEvent.set("isCanary", config.FRONT_CANARY);
		}
	} else {
		for (const [key, value] of Object.entries(prefixedUser)) {
			identifyEvent.set(key, value || false);
		}
		if (config.FRONT_CANARY) {
			identifyEvent.set("isCanary", config.FRONT_CANARY);
		}
	}
	amplitude.identify(identifyEvent, {
		user_id: user.id
	});
	return primitveUser;
});

export const UserContext = createContext<typeof getUserStore>();
export const [isPremiumModalShown, setIsPremiumModalShown] = createSignal<
	false | { source: string; unsubscribe?: boolean }
>(false);
