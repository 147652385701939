import type { RouteDataFuncArgs } from "@solidjs/router";
import { axiosClient } from "~/js/network/axiosClient";
import type { AuthRouterContext } from "~/solidJs/app/MainApp/models";
import { AirtableProxiApi } from "~/solidJs/shared/api/AirtableProxyApi";
import { HomePageApi } from "~/solidJs/shared/api/HomePageApi";
import { StylesApi } from "~/solidJs/shared/api/StylesApi";
import { BaseApi } from "~/solidJs/shared/api/baseApi";
import { queryClient } from "~/solidJs/shared/api/queryClient";
import { registratePaymentsInAnalitycs } from "~/solidJs/shared/helpers/userHelpers";

export class IndexApi extends BaseApi {
	static fetchCloudNodeListKey = "fetchCloudNodeListKey";
	static async fetchCloudNodeList() {
		try {
			const response = await axiosClient.axiosClient.get<any[]>("api/nodes/");
			const responseJson = response.data;
			if (response.status === 401) return [];
			const models = responseJson.map((item) => {
				const version = item.version;
				item.node.nodeDefinition.version = version;
				item.node.nodeDefinition.is_disabled = item.is_disabled;
				item.node.nodeDefinition.is_hidden = item.is_hidden;
				item.node.nodeDefinition.is_old = item.is_old;
				return item.node.nodeDefinition;
			});
			return models;
		} catch (error) {
			console.error(`[NODE STORAGE] Failed to get node models from remote storage.`, error);
			throw new Error(error);
		}
	}
	static prefetchIndexData() {
		queryClient.prefetchQuery({
			queryKey: [IndexApi.fetchCloudNodeListKey],
			queryFn: IndexApi.fetchCloudNodeList.bind(IndexApi)
		});

		queryClient.prefetchQuery({
			queryKey: [HomePageApi.airtableTemplatesQueryKey],
			queryFn: HomePageApi.getAirtableTemplates.bind(HomePageApi)
		});
		queryClient.prefetchQuery({
			queryKey: [HomePageApi.airtableTasksQueryKey],
			queryFn: HomePageApi.getAirtableTasks.bind(HomePageApi)
		});
		queryClient.prefetchQuery({
			queryKey: [AirtableProxiApi.fetchAndTransformMlBaseKey],
			queryFn: AirtableProxiApi.fetchAndTransformMlBase.bind(AirtableProxiApi)
		});
		queryClient.prefetchInfiniteQuery({
			queryKey: [AirtableProxiApi.onboardingFeedKey],
			queryFn: (props) =>
				AirtableProxiApi.fetchTransformOnboarding(
					props.pageParam.limit,
					props.pageParam.offset
				),
			initialPageParam: { limit: 10, offset: 0 },
			getNextPageParam: (lastPage) => ({
				offset: lastPage.offset,
				limit: 10
			}),
			pages: 1
		});
	}
	static async loadIndexData(props: RouteDataFuncArgs<AuthRouterContext>) {
		const user = await props.data.user;
		if (!user) {
			return;
		}
		this.prefetchIndexData();
		registratePaymentsInAnalitycs();
		return props.data;
	}
}
